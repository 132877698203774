.loginWrapper {
  background-color: #f0f2f5;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7rem;
  gap: 2rem;

  @media (max-width: 1100px) {
    flex-direction: column;
    gap: 2rem;
  }
}

.LeftSide {
  display: flex;
  flex-direction: column;
}

.RightSide {
  background-color: #fff;
  width: 40%;
  min-width: 500px;
  padding: 20px;

  @media (max-width: 600px) {
    min-width: 400px;
  }

  @media (max-width: 400px) {
    min-width: 300px;
  }
}

.loginForm {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 22px;
}

.forgotPassword {
  text-align: center;
  color: rgb(77, 77, 255);
}

.ButtonsWrapper {
  align-items: center;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-bottom: 1px solid rgb(228, 228, 228);
}

.LoginButton {
  width: 50%;
  text-align: center;
}

.NewAccountLink {
  text-align: center;
  background-color: green;
  color: #fff;
  padding: 1rem;
}

.DescriptionPage {
  width: 90%;

  @media (max-width: 530px) {
    min-width: 230px;
  }
}
