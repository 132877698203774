.ResetPasswordWrapper {
  display: flex;
  width: 70%;
  height: 1000px;
  justify-content: space-evenly;
  align-items: center;
}

.ResetPasswordForm {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  gap: 2rem;
  width: 100%;
  max-width: 700px;

  @media (max-width: 500px) {
    gap: 2rem;
  }
}

.formButtons {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.FormSubmitting {
  cursor: not-allowed;
}

.inputError {
  border: 1px solid red;
}

.errorMessage {
  color: red;
  font-size: 0.875em;
}
