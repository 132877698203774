.MessagesPageWrapper {
  padding-top: 5rem;
  display: flex;
  padding-left: 1rem;
  align-items: center;
  padding-bottom: 1rem;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
  }
}

.FreindsListWrapper {
  width: 30%;
}

.FriendsListWrapperMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MessagesFriendPicture {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 30px;
}

.MessagesFriendsList {
  display: flex;
  flex-direction: column;
}

.FriendMessageBtn {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1rem;

  &:hover {
    background-color: rgb(221, 221, 221);
  }
}

.MessagesSection {
  position: relative;
  width: 100%;
  min-width: 500px;
  min-height: 800px;
}

.MesageSectionMobile {
  width: 100%;
}

.NoConversationText {
  padding-left: 1rem;
  color: rgb(192, 192, 192);
}

.StartChatWrapper {
  display: flex;
  padding-left: 1rem;
  width: 100%;
  flex-direction: column;
  gap: 0.2rem;

  @media (max-width: 900px) {
    align-items: center;
  }
}

.StartChatBtn {
  width: 40px;
}

.StartNewChatText {
  color: rgb(192, 192, 192);
}

.MessagesBox {
  min-width: 1000px;
  height: 800px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin-bottom: 1rem;
  padding-top: 3rem;
  gap: 2rem;
  overflow-y: scroll;

  @media (max-width: 1060px) {
    min-width: 800px;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 850px) {
    min-width: 500px;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 600px) {
    min-width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.SendMessageTextArea {
  width: 70%;
  min-height: 100px;
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  resize: none;
  outline: none;
  color: #333;
  font-family: 'Poppins', sans-serif;
}

.SendMessageTextArea:focus {
  border-color: #007bff;
  box-shadow: 0 0 6px rgba(0, 123, 255, 0.4);
}

.SendMessage {
  display: flex;
  gap: 2rem;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.BoxBackButton {
  padding: 0.5rem;
  width: 80px;
  left: 50%;
}

.BoxBackButton:hover {
  background-color: rgb(194, 194, 194, 0.5);
}
