.PageNotFoundWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f8f9fa;
  color: #333;
  padding: 20px;
}

.PageNotFoundWrapper h1 {
  font-size: 3rem;
  font-weight: 400;
  color: #ff0019;
  margin-bottom: 10px;
}

.PageNotFoundWrapper p {
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: #666;
}

.PageNotFoundWrapper p.code {
  font-size: 1rem;
  color: #007bff;
}

.PageNotFoundWrapper a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  border: 1px solid #007bff;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.PageNotFoundWrapper a:hover {
  background-color: #007bff;
  color: #fff;
}
