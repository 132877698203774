.SettingsPageWrapper {
  display: flex;
  padding-top: 7rem;
  gap: 1rem;
}

.SettingsSection {
  display: flex;
  flex-direction: column;
  padding-right: 1rem;

  @media (max-width: 660px) {
    margin: 0 auto;
    width: 100%;
    padding: 0;
  }
}

.NavLink {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.NavLink:hover {
  background-color: var(--primary-color);
  color: white;
  font-weight: 600;
}

.SettingsNav {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  gap: 1rem;
  min-width: 250px;
  max-height: 200px;
  border-radius: 5px;
}

.test {
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.NavFormContainer {
  display: flex;
  gap: 2rem;

  @media (max-width: 1300px) {
    flex-direction: column;
  }
}

.UserProfileImageSettings {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.SettingsFormContainer {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* min-width: 800px;
  min-height: 600px; */
  border-radius: 5px;
  padding: 1.5rem;
}

.AvatarHeader {
  display: flex;
  align-items: center;
  gap: 3rem;

  @media (max-width: 660px) {
    flex-direction: column;
    padding-bottom: 2rem;
  }
}

.AvatarButtons {
  display: flex;
  gap: 0.5rem;
}

.DeleteAvatarBtn {
  background-color: rgb(211, 211, 211);
  padding: 1rem;
  text-align: center;
  border-radius: 5px;
}

.SettingsFormInputsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2rem;
  gap: 1rem;

  @media (max-width: 1085px) {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
}

.SettingsInput {
  padding: 1rem;
  border: 1px solid black;
  min-width: 300px;
}

.SettingsInput:focus {
  background-color: rgb(218, 218, 218, 0.3);
  outline: none;
  border: 1px solid blue;
  border-width: 1px;
  box-shadow: none;
}

.SettingInputWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.AstrixRequired {
  color: red;
}

.SettingsRadioBtns {
  display: flex;
  gap: 1rem;

  @media (max-width: 730px) {
    display: flex;
    flex-direction: column;
  }
}

.SettingsRadioLabel {
  display: flex;
  align-items: center;
  border: 1px solid rgb(189, 182, 182, 0.5);
  padding: 0.5rem;
  width: 100%;
  padding-left: 0;
  border-radius: 5px;
}

.SettingsStatusSection {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.SettingsSaveChangesBtn {
  margin-top: 1rem;
  width: 30%;
  text-align: center;
}

.StatusTitle {
  font-weight: 500;
  font-size: 20px;
}

.PasswordSaveChangesSection {
  padding-left: 2rem;
}

.PasswordsFormInputsWrapper {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 1rem;
}

.RadioBtnText {
  cursor: pointer;
}

.UploadFileInput {
  display: none;
}

.UploadNewFileBtn {
  height: 2.5rem;
}
