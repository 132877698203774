.SearchFriendsPageWrapper {
  display: flex;
  padding-top: 7rem;
  gap: 2rem;
}

.SearchContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
}

.SearchTitle {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 3rem;
  text-align: center;
  color: #333;
}

.SearchedUserImg {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.SearchedUserImg:hover {
  transform: scale(1.05);
}

.UserCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  transition: box-shadow 0.3s ease;
}

.UserCard:hover {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.SearchedUserText {
  font-size: 1.2rem;
  margin-top: 1rem;
  color: #555;
  font-weight: 500;
}

.ListUsersWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 2rem;
  width: 100%;
}

.NoResultsText {
  font-size: 1.5rem;
  color: #999;
}
