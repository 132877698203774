.ProfilePageWrapper {
  display: flex;
  padding-top: 7rem;
  padding-bottom: 2rem;
}

.UserPofileSection {
  display: flex;
  flex-direction: column;

  @media (max-width: 1230px) {
    gap: 6rem;
  }
}

.ProfileImagesWrapper {
  position: relative;
  height: 25%;
}

.BackgroundImage {
  width: 100%;
  height: 40.7%;
  object-fit: cover;
}

.UserProfilePicture {
  width: 150px;
  height: 150px;
  border-radius: 30px;
  object-fit: cover;
}

.ProfilePictureWrapper {
  position: absolute;
  top: 30%;
  left: 20%;
  width: 50%;

  @media (max-width: 460px) {
    left: 25%;
  }
}

.ImageAndUserName {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
}

.UsernameTitle {
  margin-bottom: 0;

  @media (max-width: 460px) {
    font-size: 20px;
  }
}

.FollowingTitle {
  @media (max-width: 460px) {
    text-align: center;
  }
}

.FollowingUserCard {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  gap: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.FollowingUserCard:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.FollowUserProfileImage {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 20px;
}

.CardWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 250px));
  gap: 1rem;
  @media (max-width: 460px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.ProfileFriendsWrapper {
  padding-left: 2rem;
  display: flex;
  padding-top: 4rem;
  flex-direction: column;

  @media (max-width: 460px) {
    padding-left: 0;
  }
}

.FollowersSection {
  @media (max-width: 460px) {
    padding-top: 2rem;
  }
}
