.registerWrapper {
  background-color: #f0f2f5;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  height: 1000px;
  padding-bottom: 2rem;
  align-items: center;
  gap: 2rem;
  @media (max-width: 850px) {
    gap: 1rem;
    padding-right: 0;
  }
}

.RightSide {
}

.LeftSide {
  padding-left: 2rem;
}

.registerForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;

  @media (max-width: 600px) {
  }

  @media (max-width: 400px) {
  }
}

.radioGroupContainer {
  display: flex;
}

.radioButtonsContainer {
  display: flex;
  justify-content: center;
  gap: 25px;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgb(228, 228, 228);
}

.RegisterButton {
  text-align: center;
  margin-top: 1rem;
}

.ChooseGenderText {
  color: var(--primary-color);
  margin: 0;
}

.DescriptionPage {
  width: 70%;
  text-align: center;
}

.TitlePage {
  margin-top: 0;
  margin-bottom: 0;
}

.InputAndError {
  display: flex;
  flex-direction: column;
  width: 70%;
  gap: 0.5rem;
  height: 60px;
}

.RegErrorMsg {
  color: red;
  font-size: 12px;
}
.LoaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Loader {
}
