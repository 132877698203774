.HeaderContainer {
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  color: #fff;
  position: fixed;
  padding-left: 1rem;
  padding-right: 1rem;
  z-index: 1000;
  width: 100%;
}

.LeftContainer {
  flex: 1;
  padding-right: 2rem;

  @media (max-width: 660px) {
    padding-right: 0;
    flex: 0.6;
  }
}

.MiddleContainer {
  position: relative;
  flex: 5;
  height: 80%;

  @media (max-width: 660px) {
    flex: 10;
  }
}

.SearchInput {
  width: 77%;
  padding: 0.6rem;
  height: 100%;
  border-radius: 10px;
  border: none;
  outline: none;
  text-indent: 32px;
}

.SearchIcon {
  position: absolute;
  top: 6px;
  left: 12px;
  color: black;
}

.RightContainer {
  flex: 0.3;
  gap: 1.2rem;
  align-items: center;
  display: flex;
  position: relative;
}

.HeaderProfileImage {
  width: 2.5rem;
  height: 2.5rem;
  margin-top: 0.3rem;
  border-radius: 20px;
  object-fit: cover;
}

.HeaderIcons {
  display: flex;
  gap: 15px;
}

.HeaderLogo {
  cursor: pointer;
}

.ProfileMenuWrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  gap: 1rem;
  color: black;
  right: 30px;
  padding: 2rem;
  top: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.ProfileMenuLink {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  padding: 0.5rem;
}

.ProfileLinkIcon {
  border-radius: 20px;
  width: 30px;
  height: 30px;
  padding: 0.2rem;
  background-color: rgb(199, 192, 192, 0.2);
}

.ProfileItemIcon {
  padding: 0.2rem;
  color: rgb(68, 68, 68);
}

.LinkLabel {
  cursor: pointer;
}

.ProfileLinkWrapper {
  width: 250px;
}

.ProfileMenuLink:hover {
  background-color: rgba(
    24,
    119,
    242,
    0.1
  ); /* Hover effect background color using RGB */
  color: rgb(24, 119, 242); /* Optional: Change text color on hover */
}

.LogoutBtn {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  font-size: 13px;
  font-weight: bold;
  background-color: rgb(192, 6, 6);
  color: white;
  margin-top: 1rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.MessageIcon {
  margin-top: 0.5rem;
}

.MobileSearchBtn {
  padding-top: 0.5rem;
  padding-left: 0.4rem;
}

.MobileLogoAndHamb {
  display: flex;
  gap: 0.4rem;
}

.MobileHeaderLogo {
  font-size: 24px;
  cursor: pointer;
}

.HamburgerMenuMobile {
  padding-top: 0.5rem;
}

.SearchFriendsMobileWrapper {
  position: absolute;
  width: 100%;
  top: 90%;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.MobileSearchFriendsInput {
  width: 80%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.MobileSearchFriendsInput:focus {
  border-color: #007bff;
  outline: none;
}

.MobileSearchFriendsInput::placeholder {
  color: #aaa;
}
